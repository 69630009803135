.navigation {
  width: 100%;
  background-image: url($bg-blue-tile-url); 
  padding: 1rem 0;
  a, span {
    display: inline;
    font-size: 1.2rem;
    font-family: $heading-font-family;
    font-weight: $font-weight-regular;
    line-height: $font-height;
    color: $bg-color;
  }
  a {
    &:hover, &:focus {
      text-decoration: underline;
    }
  }

  .nav-avatar {
    float: left;
    width: 45px;
    height: 45px;
    margin-right: 12px;
    text-decoration: none;
    @media screen and (max-width: 48em) {
      float: none;
      display: block;
      margin: 0 auto;
    }

    img {
      border-radius: 45px;
    }
  }

  .nav-title {
    letter-spacing: 0.1rem;
    padding-left: 1rem;
    margin: 0;

  }

  .nav-left {
    text-align: left;
    display: flex;
    align-items: center;
    @media screen and (max-width: 32em) {
      display: block;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .nav-center {
    display: flex;
    align-items: center;
    justify-content: center;
    a { margin-left: 10px; }
    a:first-child { margin-left: 0; }
    a:last-child { padding-right: 1rem; }
    @media screen and (max-width: 32em) {
      margin: 15px 0;
    }
  }

  .nav-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @media screen and (max-width: 32em) {
      justify-content: center;
      a:first-child { margin-left: 0; }
    }
    a { margin-left: 20px; }
    a:last-child { padding-right: 1rem; }
    input[type=text] {
      border-radius: .5rem;
      border-width: 0;
      margin: 0 1rem;
      width: 100%;
      min-width: 5rem;
      max-width: 15rem;
      @media screen and (max-width: 32em) {
        display: none;
      }
    }
  }

}
