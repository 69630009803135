.footer {
  width: 100%;
  background-color: $lighterGray;
  text-align: center;
  line-height: 1.5rem;
  padding: 3.5rem 0 3.5rem;
  margin-top: 0.5rem;
  a {
    color: $link-color;
  }
}
