.recipeItems {
  ul {
      margin:0; 
      padding:0; 
      list-style:none;
  }
  ul li {  
    padding:0 0 0 25px; 
    position:relative; 
    margin-top: 0.5rem;
  }
  ol {
    margin:0 ; 
    padding:0; 
    list-style:none;
    counter-reset: item-counter;
  }
  ol li {  
    padding:0 0 0 50px; 
    position:relative; 
    margin-top: 0.5rem;
    counter-increment: item-counter;
  }
  ol li::before {
    position: absolute;
    top: 0;
    left: 25px;
    content: counter(item-counter) ". ";
  }
}

.checkbox-container {
  display: flex;
  flex-flow: row nowrap;
  padding-bottom: 1rem;
}

/* Styling Checkbox Starts */
.checkbox-label {
    display: block;
    position: relative;
    margin: 0 1rem 0 0;
    cursor: pointer;
    line-height: 28px;
    min-height: 28px;
    min-width: 28px;
    height: 28px;
    width: 28px;
    clear: both;
    float: left;
}

ol li input {
  position: absolute;
  top: 5px;
  left: 0;
  opacity: 0.5;
}

ul li input {
  position: absolute;
  top: 5px;
  left: 0;
  opacity: 0.5;
}

.checkbox-label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}
.checkbox-label .checkbox-custom {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 24px;
    width: 24px;
    border-radius: 5px;
    border: 2px solid $blue;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
}


.checkbox-label input:checked ~ .checkbox-custom {
    background-color: $white;
    border-radius: 5px;
    border: 2px solid $blue;
    -webkit-transform: rotate(0deg) scale(1);
    -ms-transform: rotate(0deg) scale(1);
    transform: rotate(0deg) scale(1);
    opacity:1;
}


.checkbox-label .checkbox-custom::after {
    position: absolute;
    content: "";
    left: 12px;
    top: 12px;
    height: 0px;
    width: 0px;
    border-radius: 5px;
    border: solid $blue;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(0deg) scale(0);
    -ms-transform: rotate(0deg) scale(0);
    transform: rotate(0deg) scale(0);
    opacity:1;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
}


.checkbox-label input:checked ~ .checkbox-custom::after {
  -webkit-transform: rotate(45deg) scale(1);
  -ms-transform: rotate(45deg) scale(1);
  transform: rotate(45deg) scale(1);
  opacity:1;
  left: 8px;
  top: 2px;
  width: 6px;
  height: 12px;
  border: solid $blue;
  border-width: 0 3px 3px 0;
  background-color: transparent;
  border-radius: 0;
}
