
@import url('https://rsms.me/inter/inter-ui.css');

body, button { margin: 0 }

button, input, select, textarea {
  font-family: inherit;
  font-size: 100%;
}

img { max-width: 100% }
svg { max-height: 100% }

input,
select,
textarea,
fieldset {
  font-size: 1rem;
  margin-top: 0;
  margin-bottom: 0;
}

input[type=text],
input[type=datetime],
input[type=datetime-local],
input[type=email],
input[type=month],
input[type=number],
input[type=password],
input[type=search],
input[type=tel],
input[type=time],
input[type=url],
input[type=week] {
  box-sizing: border-box;
  height: 2.25rem;
  padding: .5rem .5rem;
  vertical-align: middle;
  -webkit-appearance: none;
}

select {
  box-sizing: border-box;
  line-height: 1.75;
  padding: .5rem .5rem;
}

select:not([multiple]) {
  height: 2.25rem;
  vertical-align: middle;
}

textarea {
  box-sizing: border-box;
  line-height: 1.75;
  padding: .5rem .5rem;
}

.fieldset-reset {
  padding: 0;
  margin-left: 0;
  margin-right: 0;
  border: 0;
}
.fieldset-reset legend {
  padding: 0;
}

button,
.button {
  font-size: inherit;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  box-sizing: border-box;
  line-height: 1.125rem;
  padding: .5rem 1rem;
  margin: 0;
  height: auto;
  border: 4px solid transparent;
  vertical-align: middle;
  -webkit-appearance: none;
}

button:after,
button:before,
.button:after,
.button:before {
  box-sizing: border-box;
}

::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.button-big{padding: 1.25rem 1.75rem;}
.button-with-icon{padding-left:3.75rem;}
.button-big.button-with-icon svg{position:absolute; left:.6em; top:.6em;}

.button:hover { text-decoration: none }

table {
  border-collapse: separate;
  border-spacing: 0;
  max-width: 100%;
  width: 100%;
}

th {
  text-align: left;
}

th,
td {
  line-height: inherit;
}

th { vertical-align: bottom }
td { vertical-align: top }


body {
  font-family: 'Inter UI', Helvetica, sans-serif;
  line-height: 1.5;
  font-size: 100%;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Inter UI', Helvetica, sans-serif;
  font-weight: bold;
  line-height: 1.1;
  margin-top: 1em;
  margin-bottom: .5em;
}

p, dl, ol, ul {
  font-size: 1rem;
  margin-top: 0;
  margin-bottom: 1rem;
}

pre, code, samp {
  font-family: 'Source Code Pro', Consolas, monospace;
  font-size: inherit;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow-x: scroll;
}

hr {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

blockquote {
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-left: 0;
  padding-top: .5rem;
  padding-bottom: .25rem;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: lighten($color: #007fff, $amount: 45);
  border-top: #007FFF;
  border-top-width: 4px;
  border-top-style: solid;
  width: 100%;
}
  blockquote,
  blockquote p {
    font-style: italic;
    color: darken($color: #007fff, $amount: 25);
  }
  blockquote p strong {
    color: #007fff;
  }

h1, .h1 { font-size: 2rem }
h2, .h2 { font-size: 1.5rem }
h3, .h3 { font-size: 1.25rem }
h4, .h4 { font-size: 1rem }
h5, .h5 { font-size: .875rem }
h6, .h6 { font-size: .75rem }

.list-reset {
  list-style: none;
  padding-left: 0;
}

.inline       { display: inline }
.block        { display: block }
.inline-block { display: inline-block }

.overflow-hidden { overflow: hidden }
.overflow-scroll { overflow: scroll }
.overflow-auto   { overflow: auto }

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table
}
.clearfix:after { clear: both }

.left  { float: left }
.right { float: right }

.fit { max-width: 100% }

.half-width { width: 50% }
.full-width { width: 100% }


.bold    { font-weight: bold; font-weight: bold }
.regular { font-weight: normal }
.italic  { font-style: italic }
.caps    { text-transform: uppercase; letter-spacing: .2em; }

.left-align   { text-align: left }
.center       { text-align: center }
.right-align  { text-align: right }
.justify      { text-align: justify }

.nowrap { white-space: nowrap }

.m0  { margin:        0 }
.mt0 { margin-top:    0 }
.mr0 { margin-right:  0 }
.mb0 { margin-bottom: 0 }
.ml0 { margin-left:   0 }

.m1  { margin: .5rem }
.mt1 { margin-top: .5rem }
.mr1 { margin-right: .5rem }
.mb1 { margin-bottom: .5rem }
.ml1 { margin-left: .5rem }

.m2  { margin: 1rem }
.mt2 { margin-top: 1rem }
.mr2 { margin-right: 1rem }
.mb2 { margin-bottom: 1rem }
.ml2 { margin-left: 1rem }

.m3  { margin: 2rem }
.mt3 { margin-top: 2rem }
.mr3 { margin-right: 2rem }
.mb3 { margin-bottom: 2rem }
.ml3 { margin-left: 2rem }

.m4  { margin: 4rem }
.mt4 { margin-top: 4rem }
.mr4 { margin-right: 4rem }
.mb4 { margin-bottom: 4rem }
.ml4 { margin-left: 4rem }

.mxn1 { margin-left: -.5rem; margin-right: -.5rem; }
.mxn2 { margin-left: -1rem; margin-right: -1rem; }
.mxn3 { margin-left: -2rem; margin-right: -2rem; }
.mxn4 { margin-left: -4rem; margin-right: -4rem; }

.mx-auto { margin-left: auto; margin-right: auto; }
.p1  { padding: .5rem }
.py1 { padding-top: .5rem; padding-bottom: .5rem }
.px1 { padding-left: .5rem; padding-right: .5rem }

.p2  { padding: 1rem }
.py2 { padding-top: 1rem; padding-bottom: 1rem }
.px2 { padding-left: 1rem; padding-right: 1rem }

.p3  { padding: 2rem }
.py3 { padding-top: 2rem; padding-bottom: 2rem }
.px3 { padding-left: 2rem; padding-right: 2rem }

.p4  { padding: 4rem }
.py4 { padding-top: 4rem; padding-bottom: 4rem }
.px4 { padding-left: 4rem; padding-right: 4rem }

/* Basscss Responsive White Space */

@media (min-width: 40em) {

  .sm-m0  { margin:        0 }
  .sm-mt0 { margin-top:    0 }
  .sm-mr0 { margin-right:  0 }
  .sm-mb0 { margin-bottom: 0 }
  .sm-ml0 { margin-left:   0 }

  .sm-m1  { margin: .5rem }
  .sm-mt1 { margin-top: .5rem }
  .sm-mr1 { margin-right: .5rem }
  .sm-mb1 { margin-bottom: .5rem }
  .sm-ml1 { margin-left: .5rem }

  .sm-m2  { margin: 1rem }
  .sm-mt2 { margin-top: 1rem }
  .sm-mr2 { margin-right: 1rem }
  .sm-mb2 { margin-bottom: 1rem }
  .sm-ml2 { margin-left: 1rem }

  .sm-m3  { margin: 2rem }
  .sm-mt3 { margin-top: 2rem }
  .sm-mr3 { margin-right: 2rem }
  .sm-mb3 { margin-bottom: 2rem }
  .sm-ml3 { margin-left: 2rem }

  .sm-m4  { margin: 4rem }
  .sm-mt4 { margin-top: 4rem }
  .sm-mr4 { margin-right: 4rem }
  .sm-mb4 { margin-bottom: 4rem }
  .sm-ml4 { margin-left: 4rem }

  .sm-mxn1 { margin-left: -.5rem; margin-right: -.5rem; }
  .sm-mxn2 { margin-left: -1rem; margin-right: -1rem; }
  .sm-mxn3 { margin-left: -2rem; margin-right: -2rem; }
  .sm-mxn4 { margin-left: -4rem; margin-right: -4rem; }

  .sm-mx-auto { margin-left: auto; margin-right: auto; }

}

@media (min-width: 300px) {

  .xs-m0  { margin:        0 }
  .xs-mt0 { margin-top:    0 }
  .xs-mr0 { margin-right:  0 }
  .xs-mb0 { margin-bottom: 0 }
  .xs-ml0 { margin-left:   0 }

  .xs-m1  { margin: .5rem }
  .xs-mt1 { margin-top: .5rem }
  .xs-mr1 { margin-right: .5rem }
  .xs-mb1 { margin-bottom: .5rem }
  .xs-ml1 { margin-left: .5rem }

  .xs-m2  { margin: 1rem }
  .xs-mt2 { margin-top: 1rem }
  .xs-mr2 { margin-right: 1rem }
  .xs-mb2 { margin-bottom: 1rem }
  .xs-ml2 { margin-left: 1rem }

  .xs-m3  { margin: 2rem }
  .xs-mt3 { margin-top: 2rem }
  .xs-mr3 { margin-right: 2rem }
  .xs-mb3 { margin-bottom: 2rem }
  .xs-ml3 { margin-left: 2rem }

  .xs-m4  { margin: 4rem }
  .xs-mt4 { margin-top: 4rem }
  .xs-mr4 { margin-right: 4rem }
  .xs-mb4 { margin-bottom: 4rem }
  .xs-ml4 { margin-left: 4rem }

  .xs-mxn1 { margin-left: -.5rem; margin-right: -.5rem; }
  .xs-mxn2 { margin-left: -1rem; margin-right: -1rem; }
  .xs-mxn3 { margin-left: -2rem; margin-right: -2rem; }
  .xs-mxn4 { margin-left: -4rem; margin-right: -4rem; }

  .xs-mx-auto { margin-left: auto; margin-right: auto; }

}

@media (min-width: 52em) {

  .md-m0  { margin:        0 }
  .md-mt0 { margin-top:    0 }
  .md-mr0 { margin-right:  0 }
  .md-mb0 { margin-bottom: 0 }
  .md-ml0 { margin-left:   0 }

  .md-m1  { margin: .5rem }
  .md-mt1 { margin-top: .5rem }
  .md-mr1 { margin-right: .5rem }
  .md-mb1 { margin-bottom: .5rem }
  .md-ml1 { margin-left: .5rem }

  .md-m2  { margin: 1rem }
  .md-mt2 { margin-top: 1rem }
  .md-mr2 { margin-right: 1rem }
  .md-mb2 { margin-bottom: 1rem }
  .md-ml2 { margin-left: 1rem }

  .md-m3  { margin: 2rem }
  .md-mt3 { margin-top: 2rem }
  .md-mr3 { margin-right: 2rem }
  .md-mb3 { margin-bottom: 2rem }
  .md-ml3 { margin-left: 2rem }

  .md-m4  { margin: 4rem }
  .md-mt4 { margin-top: 4rem }
  .md-mr4 { margin-right: 4rem }
  .md-mb4 { margin-bottom: 4rem }
  .md-ml4 { margin-left: 4rem }

  .md-mxn1 { margin-left: -.5rem; margin-right: -.5rem; }
  .md-mxn2 { margin-left: -1rem; margin-right: -1rem; }
  .md-mxn3 { margin-left: -2rem; margin-right: -2rem; }
  .md-mxn4 { margin-left: -4rem; margin-right: -4rem; }

  .md-mx-auto { margin-left: auto; margin-right: auto; }

}
@media (min-width: 64em) {

  .lg-m0  { margin:        0 }
  .lg-mt0 { margin-top:    0 }
  .lg-mr0 { margin-right:  0 }
  .lg-mb0 { margin-bottom: 0 }
  .lg-ml0 { margin-left:   0 }

  .lg-m1  { margin: .5rem }
  .lg-mt1 { margin-top: .5rem }
  .lg-mr1 { margin-right: .5rem }
  .lg-mb1 { margin-bottom: .5rem }
  .lg-ml1 { margin-left: .5rem }

  .lg-m2  { margin: 1rem }
  .lg-mt2 { margin-top: 1rem }
  .lg-mr2 { margin-right: 1rem }
  .lg-mb2 { margin-bottom: 1rem }
  .lg-ml2 { margin-left: 1rem }

  .lg-m3  { margin: 2rem }
  .lg-mt3 { margin-top: 2rem }
  .lg-mr3 { margin-right: 2rem }
  .lg-mb3 { margin-bottom: 2rem }
  .lg-ml3 { margin-left: 2rem }

  .lg-m4  { margin: 4rem }
  .lg-mt4 { margin-top: 4rem }
  .lg-mr4 { margin-right: 4rem }
  .lg-mb4 { margin-bottom: 4rem }
  .lg-ml4 { margin-left: 4rem }

  .lg-mxn1 { margin-left: -.5rem; margin-right: -.5rem; }
  .lg-mxn2 { margin-left: -1rem; margin-right: -1rem; }
  .lg-mxn3 { margin-left: -2rem; margin-right: -2rem; }
  .lg-mxn4 { margin-left: -4rem; margin-right: -4rem; }

  .lg-mx-auto { margin-left: auto; margin-right: auto; }

}

@media (min-width: 300px) {

  .xs-p1  { padding: .5rem }
  .xs-py1 { padding-top: .5rem; padding-bottom: .5rem }
  .xs-px1 { padding-left: .5rem; padding-right: .5rem }

  .xs-p2  { padding: 1rem }
  .xs-py2 { padding-top: 1rem; padding-bottom: 1rem }
  .xs-px2 { padding-left: 1rem; padding-right: 1rem }

  .xs-p3  { padding: 2rem }
  .xs-py3 { padding-top: 2rem; padding-bottom: 2rem }
  .xs-px3 { padding-left: 2rem; padding-right: 2rem }

  .xs-p4  { padding: 4rem }
  .xs-py4 { padding-top: 4rem; padding-bottom: 4rem }
  .xs-px4 { padding-left: 4rem; padding-right: 4rem }

}

@media (min-width: 40em) {

  .sm-p1  { padding: .5rem }
  .sm-py1 { padding-top: .5rem; padding-bottom: .5rem }
  .sm-px1 { padding-left: .5rem; padding-right: .5rem }

  .sm-p2  { padding: 1rem }
  .sm-py2 { padding-top: 1rem; padding-bottom: 1rem }
  .sm-px2 { padding-left: 1rem; padding-right: 1rem }

  .sm-p3  { padding: 2rem }
  .sm-py3 { padding-top: 2rem; padding-bottom: 2rem }
  .sm-px3 { padding-left: 2rem; padding-right: 2rem }

  .sm-p4  { padding: 4rem }
  .sm-py4 { padding-top: 4rem; padding-bottom: 4rem }
  .sm-px4 { padding-left: 4rem; padding-right: 4rem }

}
@media (min-width: 52em) {

  .md-p1  { padding: .5rem }
  .md-py1 { padding-top: .5rem; padding-bottom: .5rem }
  .md-px1 { padding-left: .5rem; padding-right: .5rem }

  .md-p2  { padding: 1rem }
  .md-py2 { padding-top: 1rem; padding-bottom: 1rem }
  .md-px2 { padding-left: 1rem; padding-right: 1rem }

  .md-p3  { padding: 2rem }
  .md-py3 { padding-top: 2rem; padding-bottom: 2rem }
  .md-px3 { padding-left: 2rem; padding-right: 2rem }

  .md-p4  { padding: 4rem }
  .md-py4 { padding-top: 4rem; padding-bottom: 4rem }
  .md-px4 { padding-left: 4rem; padding-right: 4rem }

}
@media (min-width: 64em) {

  .lg-p1  { padding: .5rem }
  .lg-py1 { padding-top: .5rem; padding-bottom: .5rem }
  .lg-px1 { padding-left: .5rem; padding-right: .5rem }

  .lg-p2  { padding: 1rem }
  .lg-py2 { padding-top: 1rem; padding-bottom: 1rem }
  .lg-px2 { padding-left: 1rem; padding-right: 1rem }

  .lg-p3  { padding: 2rem }
  .lg-py3 { padding-top: 2rem; padding-bottom: 2rem }
  .lg-px3 { padding-left: 2rem; padding-right: 2rem }

  .lg-p4  { padding: 4rem }
  .lg-py4 { padding-top: 4rem; padding-bottom: 4rem }
  .lg-px4 { padding-left: 4rem; padding-right: 4rem }

}

.sm-show, .md-show, .lg-show {
  display: none !important
}

@media (min-width: 40em) {
  .sm-show { display: block !important }
}

@media (min-width: 52em) {
  .md-show { display: block !important }
}

@media (min-width: 64em) {
  .lg-show { display: block !important }
}


@media (min-width: 40em) {
  .sm-hide { display: none !important }
}

@media (min-width: 52em) {
  .md-hide { display: none !important }
}

@media (min-width: 64em) {
  .lg-hide { display: none !important }
}

.display-none { display: none !important }

.hide {
  position: absolute !important;
  height: 4px;
  width: 4px;
  overflow: hidden;
  clip: rect(4px, 4px, 4px, 4px);
}


.relative { position: relative }
.absolute { position: absolute }
.fixed    { position: fixed }

.top-0    { top: 0 }
.right-0  { right: 0 }
.bottom-0 { bottom: 0 }
.left-0   { left: 0 }

.z1 { z-index: 1 }
.z2 { z-index: 2 }
.z3 { z-index: 3 }
.z4 { z-index: 4 }

.container {
  max-width: 64em;
  margin-left: auto;
  margin-right: auto;
  padding: 0 2rem;
}
.col {
  float: left;
  box-sizing: border-box;
}

.col-right {
  float: right;
  box-sizing: border-box;
}

.col-1 {
  width: 8.33333%;
}

.col-2 {
  width: 16.66667%;
}

.col-3 {
  width: 25%;
}

.col-4 {
  width: 33.33333%;
}

.col-5 {
  width: 41.66667%;
}

.col-6 {
  width: 50%;
}

.col-7 {
  width: 58.33333%;
}

.col-8 {
  width: 66.66667%;
}

.col-9 {
  width: 75%;
}

.col-10 {
  width: 83.33333%;
}

.col-11 {
  width: 91.66667%;
}

.col-12 {
  width: 100%;
}
@media (min-width: 40em) {

  .sm-col {
    float: left;
    box-sizing: border-box;
  }

  .sm-col-right {
    float: right;
    box-sizing: border-box;
  }

  .sm-col-1 {
    width: 8.33333%;
  }

  .sm-col-2 {
    width: 16.66667%;
  }

  .sm-col-3 {
    width: 25%;
  }

  .sm-col-4 {
    width: 33.33333%;
  }

  .sm-col-5 {
    width: 41.66667%;
  }

  .sm-col-6 {
    width: 50%;
  }

  .sm-col-7 {
    width: 58.33333%;
  }

  .sm-col-8 {
    width: 66.66667%;
  }

  .sm-col-9 {
    width: 75%;
  }

  .sm-col-10 {
    width: 83.33333%;
  }

  .sm-col-11 {
    width: 91.66667%;
  }

  .sm-col-12 {
    width: 100%;
  }

}
@media (min-width: 52em) {

  .md-col {
    float: left;
    box-sizing: border-box;
  }

  .md-col-right {
    float: right;
    box-sizing: border-box;
  }

  .md-col-1 {
    width: 8.33333%;
  }

  .md-col-2 {
    width: 16.66667%;
  }

  .md-col-3 {
    width: 25%;
  }

  .md-col-4 {
    width: 33.33333%;
  }

  .md-col-5 {
    width: 41.66667%;
  }

  .md-col-6 {
    width: 50%;
  }

  .md-col-7 {
    width: 58.33333%;
  }

  .md-col-8 {
    width: 66.66667%;
  }

  .md-col-9 {
    width: 75%;
  }

  .md-col-10 {
    width: 83.33333%;
  }

  .md-col-11 {
    width: 91.66667%;
  }

  .md-col-12 {
    width: 100%;
  }

}
@media (min-width: 64em) {

  .lg-col {
    float: left;
    box-sizing: border-box;
  }

  .lg-col-right {
    float: right;
    box-sizing: border-box;
  }

  .lg-col-1 {
    width: 8.33333%;
  }

  .lg-col-2 {
    width: 16.66667%;
  }

  .lg-col-3 {
    width: 25%;
  }

  .lg-col-4 {
    width: 33.33333%;
  }

  .lg-col-5 {
    width: 41.66667%;
  }

  .lg-col-6 {
    width: 50%;
  }

  .lg-col-7 {
    width: 58.33333%;
  }

  .lg-col-8 {
    width: 66.66667%;
  }

  .lg-col-9 {
    width: 75%;
  }

  .lg-col-10 {
    width: 83.33333%;
  }

  .lg-col-11 {
    width: 91.66667%;
  }

  .lg-col-12 {
    width: 100%;
  }

}


.flex { display: flex }

.flex-column  { flex-direction: column }
.flex-wrap    { flex-wrap: wrap }

.flex-center   { align-items: center }
.flex-baseline { align-items: baseline }
.flex-stretch  { align-items: stretch }
.flex-start    { align-items: flex-start }
.flex-end      { align-items: flex-end }

.flex-justify  { justify-content: space-between }

.flex-first { order: -1 }
.flex-last  { order: 1024 }

.flex-auto { flex: 1 1 auto }
.flex-grow { flex: 1 0 auto }
.flex-none { flex: none }

.flex > div { box-sizing: border-box }
@media (min-width: 40em) {
  .sm-flex { display: flex }
  .sm-flex > div { box-sizing: border-box }
}
@media (min-width: 52em) {
  .md-flex { display: flex }
  .md-flex > div { box-sizing: border-box }
}
@media (min-width: 64em) {
  .lg-flex { display: flex }
  .lg-flex > div { box-sizing: border-box }
}


body {
  color: #111;
  background-color: white;
}

a {
  color: #007FFF;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

pre, code {
  border-radius: 3px;
}

hr {
  border: 0;
  border-bottom-style: solid;
  border-bottom-width: 4px;
  border-bottom-color: rgba(0,0,0,.125);
}

.button {
  color: white;
  background-color: #007FFF;
  border-radius: 3px;
  transition-duration: .05s;
  transition-timing-function: ease-out;
  transition-property: background-color;
}

.button:focus {
  outline: none;
  border-color: rgba(0,0,0,.125);
  box-shadow: 0 0 0 3px rgba(0,0,0,.25);
}

.button:active,
.button.is-active {
  box-shadow: inset 0 0 0 20rem rgba(0,0,0,.125),
    inset 0 3px 4px 0 rgba(0,0,0,.25),
    0 0 4px rgba(0,0,0,.125);
}

.button:disabled,
.button.is-disabled {
  opacity: .5;
}

.field-light {
  background-color: white;
  transition: box-shadow .2s ease;
  border-style: solid;
  border-width: 4px;
  border-color: rgba(0,0,0,.125);
  border-radius: 3px;
}

.field-light:focus {
  outline: none;
  border-color: #007FFF;
  box-shadow: 0 0 0 2px rgba(0, 127, 255, 0.5);
}

.field-light:disabled {
  background-color: rgba(0,0,0,.125);
  opacity: .5;
}

.field-light:read-only:not(select) {
  background-color: rgba(0,0,0,.125);
}

.field-light:invalid {
  border-color: #ff4136;
}

.field-light.is-success {
  border-color: #2ecc40;
}

.field-light.is-warning {
  border-color: #ffdc00;
}

.field-light.is-error {
  border-color: #ff4136;
}

.field-dark {
  color: white;
  background-color: rgba(0,0,0,.25);
  border: 4px solid rgba(0,0,0,.0625);
  border-radius: 3px;
  border-radius: 3px;
}

.field-dark::placeholder {
  color: rgba(255,255,255,.75);
}

.field-dark:focus {
  outline: 0;
  border: 4px solid rgba(255,255,255,.5);
  box-shadow: 0 0 0 2px rgba(255,255,255,.25);
}

.field-dark:read-only:not(select) {
  background-color: rgba(255,255,255,.25);
}

.field-dark:invalid {
  border-color: #ff4136;
}

.field-dark.is-success {
  border-color: #2ecc40;
}

.field-dark.is-warning {
  border-color: #ffdc00;
}

.field-dark.is-error {
  border-color: #ff4136;
}

input[type=range] {
  vertical-align: middle;
  background-color: transparent;
}

.progress {
  display: block;
  width: 100%;
  height: 0.5625rem;
  margin: .5rem 0;
  background-color: rgba(0,0,0,.125);
  border: 0;
  border-radius: 10000px;
  overflow: hidden;
  -webkit-appearance: none;
}

.progress::-webkit-progress-bar {
  -webkit-appearance: none;
  background-color: rgba(0,0,0,.125)
}

.progress::-webkit-progress-value {
  -webkit-appearance: none;
  background-color: currentColor;
}

.progress::-moz-progress-bar {
  background-color: currentColor;
}


.table-light th,
.table-light td {
  border-bottom-width: 4px;
  border-bottom-style: solid;
  border-bottom-color: rgba(0,0,0,.125);
}

.table-light tr:last-child td {
  border-bottom: 0;
}

.button-outline {
  position: relative;
  z-index: 2;
  color: inherit;
  background-color: transparent;
  border-radius: 3px;
  border: 4px solid currentcolor;
  transition-duration: .1s;
  transition-timing-function: ease-out;
  transition-property: box-shadow, background-color;
}

.button-outline:before {
  content: '';
  display: block;
  position: absolute;
  z-index: -1;
  top: -4px;
  right: -4px;
  bottom: -4px;
  left: -4px;
  border: 4px solid transparent;
  background-color: currentcolor;
  border-radius: 3px;
  transition-duration: .1s;
  transition-timing-function: ease-out;
  transition-property: opacity;
  opacity: 0;
}

.button-outline:hover {
  box-shadow: none;
}

.button-outline:hover:before,
.button-outline:focus:before {
  opacity: .125;
}

.button-outline:focus {
  outline: none;
  border: 4px solid currentcolor;
  box-shadow: 0 0 0 2px;
}

.button-outline:active,
.button-outline.is-active {
  box-shadow: inset 0 4px 5px 0, 0 0 4px;
}

.button-outline:disabled,
.button-outline.is-disabled {
  opacity: .5;
}

.button-transparent {
  position: relative;
  z-index: 2;
  color: inherit;
  background-color: transparent;
  border-radius: 0;
  border: 4px solid transparent;
  transition-duration: .1s;
  transition-timing-function: ease-out;
  transition-property: box-shadow;
}

.button-transparent:before {
  content: '';
  display: block;
  position: absolute;
  z-index: -1;
  top: -4px;
  right: -4px;
  bottom: -4px;
  left: -4px;
  border: 4px solid transparent;
  background-color: currentcolor;
  transition-duration: .1s;
  transition-timing-function: ease-out;
  transition-property: opacity;
  opacity: 0;
}

.button-transparent:hover {
  box-shadow: none;
}

.button-transparent:hover:before, 
.button-transparent:focus:before {
  opacity: .09375;
}

.button-transparent:focus {
  outline: none;
  border-color: transparent;
  box-shadow: 0 0 0 2px; 
}

.button-transparent:active,
.button-transparent.is-active {
  box-shadow: none;
}

.button-transparent:active:before,
.button-transparent.is-active:before {
  opacity: .0625;
}

.button-transparent:disabled,
.button-transparent.is-disabled {
  opacity: .5;
}

.bg-cover   { background-size: cover }
.bg-contain { background-size: contain }

.bg-center  { background-position: center }
.bg-top     { background-position: top }
.bg-right   { background-position: right }
.bg-bottom  { background-position: bottom }
.bg-left    { background-position: left }

.border {
  border-style: solid;
  border-width: 4px;
  border-color: rgba(0,0,0,.125);
}

.border-top {
  border-top-style: solid;
  border-top-width: 4px;
  border-top-color: rgba(0,0,0,.125);
}

.border-right {
  border-right-style: solid;
  border-right-width: 4px;
  border-right-color: rgba(0,0,0,.125);
}

.border-bottom {
  border-bottom-style: solid;
  border-bottom-width: 4px;
  border-bottom-color: rgba(0,0,0,.125);
}

.border-left {
  border-left-style: solid;
  border-left-width: 4px;
  border-left-color: rgba(0,0,0,.125);
}

.rounded { border-radius: 3px }
.circle  { border-radius: 50% }

.rounded-top    { border-radius: 3px 3px 0 0 }
.rounded-right  { border-radius: 0 3px 3px 0 }
.rounded-bottom { border-radius: 0 0 3px 3px }
.rounded-left   { border-radius: 3px 0 0 3px }

.not-rounded { border-radius: 0 }

.black, .dark-gray   { color: #111 }
.gray, .mid-gray     { color: #aaa }
.silver, .light-gray { color: #ddd }
.white { color: #fff }

.aqua  { color: #7fdbff }
.blue  { color: #007FFF }
.navy  { color: #001f3f }
.teal  { color: #39cccc }
.green { color: #2ecc40 }
.olive { color: #3d9970 }
.lime  { color: #01ff70 }

.yellow  { color: #ffdc00 }
.orange  { color: #ff851b }
.red     { color: #ff4136 }
.fuchsia { color: #f012be }
.purple  { color: #b10dc9 }
.maroon  { color: #85144b }

.bg-black, .bg-dark-gray   { background-color: #111 }
.bg-gray, .bg-mid-gray     { background-color: #aaa }
.bg-silver, .bg-light-gray { background-color: #ddd }
.bg-white  { background-color: #fff }

.bg-aqua  { background-color: #7fdbff }
.bg-blue  { background-color: #007FFF }
.bg-navy  { background-color: #001f3f }
.bg-teal  { background-color: #39cccc }
.bg-green { background-color: #2ecc40 }
.bg-olive { background-color: #3d9970 }
.bg-lime  { background-color: #01ff70 }

.bg-yellow  { background-color: #ffdc00 }
.bg-orange  { background-color: #ff851b }
.bg-red     { background-color: #ff4136 }
.bg-fuchsia { background-color: #f012be }
.bg-purple  { background-color: #b10dc9 }
.bg-maroon  { background-color: #85144b }

.bg-darken-1 { background-color: rgba(0,0,0,.0625) }
.bg-darken-2 { background-color: rgba(0,0,0,.125) }
.bg-darken-3 { background-color: rgba(0,0,0,.25) }
.bg-darken-4 { background-color: rgba(0,0,0,.5) }

.border-aqua  { border-color: #7fdbff }
.border-blue  { border-color: #007FFF }
.border-navy  { border-color: #001f3f }
.border-teal  { border-color: #39cccc }
.border-green { border-color: #2ecc40 }
.border-olive { border-color: #3d9970 }
.border-lime  { border-color: #01ff70 }

.border-yellow  { border-color: #ffdc00 }
.border-orange  { border-color: #ff851b }
.border-red     { border-color: #ff4136 }
.border-fuchsia { border-color: #f012be }
.border-purple  { border-color: #b10dc9 }
.border-maroon  { border-color: #85144b }

.border-black  { border-color: #111 }
.border-gray   { border-color: #aaa }
.border-silver { border-color: #ddd }
.border-white  { border-color: #fff }

.border-darken-1 { border-color: rgba(0,0,0,.0625) }
.border-darken-2 { border-color: rgba(0,0,0,.125) }
.border-darken-3 { border-color: rgba(0,0,0,.25) }
.border-darken-4 { border-color: rgba(0,0,0,.5) }
.muted { opacity: .5 }

/* Start of chowdown CSS */

img{width:100%;}
.no-line-height{line-height:0;}

.bg-blue-tile{background: #007FFF url($bg-blue-tile-url); position:relative;}
@media all and (min-width:40em){
.bg-blue-tile:after{position:absolute; top:0; left:0; right:0; bottom:0; content:" "; box-shadow:inset 0 0 100px 20px #007FFF; z-index:1; pointer-events:none;}
}

.max-width-4{max-width:64em}
.max-width-3{max-width:52em}
.max-width-2{max-width:40em}

.search input{border-radius:5px; border:2px solid #eee; box-shadow:0 0 14px rgba(#007FFF,0.1); width:100%; padding:1.5rem}
.search input:focus{outline:none; border:2px solid #007FFF;}
.search{transition:.3s ease height; height:90vh; display:flex; flex-direction: column; justify-content:center;}

.hero{height:150px;}
.post h1{margin-top:6rem}
.post ol, .post ul{}
.post li{margin-bottom:1rem; padding:0 0 0 1rem;}
.post li:before{content:"- "; position:absolute; left:0; color:#007FFF}

a .image{transition:.2s ease all; opacity:1;}
a:hover .image{opacity:0.8;}
.ingredients p{margin-bottom:0.5rem;}

.sell .sm-col a{text-decoration:none; color:#fff; border-bottom:2px solid rgba(255,255,255,0.5);}

.ratio{padding-top:50%; background-position: center center; background-repeat:no-repeat;}

@media all and (max-width:40em){
  .left-align{text-align:center;}
  .right-align{text-align:center;}
}

@media all and (max-width: 300px){
  .title{font-size:1.3rem; text-align:center; box-sizing:border-box; width:100%;}
  .xs-center{text-align: center}
  .site-title{bottom:-28px; top:auto; left:50%; margin-left:-25px; border-radius:200px; transition:.2s ease bottom}
  .site-title:hover{bottom:-14px;}
  .recipes .sm-col{border-bottom:3px solid white}
}

.title-shadow {
  text-shadow: 1px 1px 4px black;
}